var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"identification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เลขบัตรประจำตัวประชาชน"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"เลขบัตรประจำตัวประชาชน","type":"text"},model:{value:(_vm.identification),callback:function ($$v) {_vm.identification=$$v},expression:"identification"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"day","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"วัน"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"01 เลขวัน 2 หลัก","type":"text"},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"month","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เดือน"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"01 เลขเดือน 2 หลัก","type":"text"},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"year","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ปีเกิด (ค.ศ.) "}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"2001 ปี ค.ศ.","type":"text"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"pin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"พิน 6 หลัก"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"123456 PIN","type":"text"},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"mobile_phone_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null,"aria-invalid":false,"placeholder":"08XXXXXXXX เบอร์โทร","type":"text"},model:{value:(_vm.mobilePhoneNo),callback:function ($$v) {_vm.mobilePhoneNo=$$v},expression:"mobilePhoneNo"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" ส่ง OTP ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }