<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
        v-slot="{ errors }"
        name="identification"
        rules="required"
      >
        <b-form-group label="เลขบัตรประจำตัวประชาชน">
          <b-form-input
            v-model="identification"
            :state="errors[0] ? false : null"
            :aria-invalid="false"
            placeholder="เลขบัตรประจำตัวประชาชน"
            type="text"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>

      <b-row class="mt-4">
        <b-col md="4">
          <ValidationProvider v-slot="{ errors }" name="day" rules="required">
            <b-form-group label="วัน">
              <b-form-input
                v-model="day"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="01 เลขวัน 2 หลัก"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="4">
          <ValidationProvider v-slot="{ errors }" name="month" rules="required">
            <b-form-group label="เดือน">
              <b-form-input
                v-model="month"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="01 เลขเดือน 2 หลัก"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col md="4">
          <ValidationProvider v-slot="{ errors }" name="year" rules="required">
            <b-form-group label="ปีเกิด (ค.ศ.) ">
              <b-form-input
                v-model="year"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="2001 ปี ค.ศ."
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider v-slot="{ errors }" name="pin" rules="required">
            <b-form-group label="พิน 6 หลัก">
              <b-form-input
                v-model="pin"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="123456 PIN"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider
            v-slot="{ errors }"
            name="mobile_phone_no"
            rules="required"
          >
            <b-form-group label="เบอร์โทรศัพท์">
              <b-form-input
                v-model="mobilePhoneNo"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="08XXXXXXXX เบอร์โทร"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>

      <div class="d-flex">
        <b-button variant="primary" class="ml-auto" type="submit">
          ส่ง OTP
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  name: 'MobileBankingForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      identification: '',
      year: '',
      month: '',
      day: '',
      pin: '',
      mobilePhoneNo: '',
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    await this.setData()
  },
  methods: {
    async setData() {
      this.identification = this.value?.identification || ''
      this.year = this.value?.year || ''
      this.month = this.value?.month || ''
      this.day = this.value?.day || ''
      this.pin = this.value?.pin || ''
      this.mobilePhoneNo = this.value?.mobilePhoneNo || ''
    },
    onAgentIdChange(agent_id) {
      this.agent_id = agent_id
    },
    onSubmit() {
      this.$emit('submit', {
        identification: this.identification,
        year: this.year,
        month: this.month,
        day: this.day,
        pin: this.pin,
        mobilePhoneNo: this.mobilePhoneNo,
        license: this.license,
      })
    },
    onCancel() {
      this.$emit('close')
    },
    verifyAccount() {},
  },
}
</script>
